
import React, { Suspense, lazy, useEffect, useState } from 'react';
import './App.css';
import './CompanyCustom.css'
import './font.css';
import './style.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './Pages/i18n';
import MaintainenceScreen from './Components/MaintainenceScreen';
import TitleManager from './Pages/TitleManager';
import { AuthProvider } from './contexts/AuthContext';
import NotFoundPage from './Components/Error/NotFoundPage';
import TextColorWrapper from './Components/TextColorWrapper'; // Import TextColorWrapper
import SpeechRecognitionComponent from './Components/SpeechRecognitionComponent';
import AndroidPermission from './Pages/Permission/AndroidPermission';
import AndroidWeb from './Pages/AndroidWeb';
// import Testing from './Pages/Testing';

const DesktopChatBox = lazy(() => import('./Pages/videoCall/Desktop/ChatBox'));
const MobileChatBox = lazy(() => import('./Pages/videoCall/Mobile/ChatBox'));
const Connect = lazy(() => import('./Pages/Connect'));
const SelectLanguage = lazy(() => import('./Pages/SelectLanguage'));
const SelectCategory = lazy(() => import('./Pages/SelectCategory'));
const Waiting = lazy(() => import('./Pages/Waiting'));
const VideoCall = lazy(() => import('././Pages/videoCall/VideoCall'));
const Feedback = lazy(() => import('./Pages/Feedback'));
const Thankyou = lazy(() => import('./Pages/Thankyou'));
const SubCategory = lazy(() => import('./Pages/SubCategory'));
const ActionWeb = lazy(() => import('./Pages/ActionWeb'));
const CallConnect = lazy(() => import('./Pages/CallConnect'));
const Screensaver = lazy(() => import('././Pages/Screensaver'));
const Terms = lazy(() => import('./Pages/Terms'));
const Permissions = lazy(() => import('./Pages/Permission/Permissions'));
const Internet = lazy(() => import('./Components/Internet'));

const App = () => {
  const [isStaging, setIsStaging] = useState(false);
  const [isLocal, setIsLocal] = useState(false);
  const [isPreProd, setIsPreProd] = useState(false);
  
  useEffect(() => {
    const url = process.env.REACT_APP_BASE_URL_API;
    if (url === 'https://staging-api.voncierge.tech/api/') {
      setIsStaging(true);
      setIsLocal(false);
      setIsPreProd(false);
    } else if (url === 'https://development-api.voncierge.tech/api/') {
      setIsLocal(false);
      setIsStaging(false);
      setIsPreProd(true);
    } else {
      setIsStaging(false);
      setIsLocal(true);
      setIsPreProd(false);
    }
  }, []);

  return (
    
    <div className='homePage'>
      <I18nextProvider i18n={i18n}>
        <AuthProvider>
          <Router>
            <TextColorWrapper> {/* Wrap the routes with TextColorWrapper */}
              <EnvironmentIndicator isStaging={isStaging} isLocal={isLocal} isPreProd={isPreProd} />
              <Suspense>
                <Routes>
                  <Route path="/permissions" element={<Permissions />} />
                  {/* <Route path="/" element={<AndroidWeb />} /> */}
                  <Route path="/" element={<Screensaver />} />

                  <Route path="/android" element={<AndroidPermission />} />

                  <Route path="/Connect" element={<Connect />} />  
                  <Route path="/languages" element={<SelectLanguage />} />  
                  <Route path="/:langCode/categories" element={<SelectCategory />} />  
                  <Route path="/:langCode/category/:categoryId/queue" element={<Waiting />} />
                  <Route path="/call" element={<VideoCall />} />  
                  <Route path="/desktop-chatbox" element={<DesktopChatBox />} />
                  <Route path="/mobile-chatbox" element={<MobileChatBox />} />
                  <Route path="/feedback" element={<Feedback />} />
                  <Route path="/thankyou" element={<Thankyou />} />
                  <Route path="/:langCode/category/:categoryId/subCategories" element={<SubCategory />} />
                  <Route path="/webView" element={<ActionWeb />} />
                  <Route path="/callConnect" element={<CallConnect />} />
                  <Route path="/maintenance" element={<MaintainenceScreen />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/internet" element={<Internet />} />
                  <Route path="/speech" element={ <SpeechRecognitionComponent />  } />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Suspense>
            </TextColorWrapper>
          </Router>
        </AuthProvider>
      </I18nextProvider>
    </div>
  );
};

const getBrowserName = () => {
  const userAgent = navigator.userAgent;
  if (userAgent.includes("CriOS")) {
    return "Chrome on iPhone";
  } else if (userAgent.includes("Firefox")) {
    return "Firefox";
  } else if (userAgent.includes("SamsungBrowser")) {
    return "Samsung Internet";
  } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    return "Opera";
  } else if (userAgent.includes("Edge")) {
    return "Microsoft Edge";
  } else if (userAgent.includes("Chrome")) {
    return "Google Chrome";
  } else if (userAgent.includes("Safari")) {
    return "Safari";
  } else {
    return "Unknown";
  }
};

const EnvironmentIndicator = ({ isStaging, isLocal, isPreProd }) => {
  const location = useLocation();
  const [browserName, setBrowserName] = useState('');

  useEffect(() => {
    const name = getBrowserName();
    setBrowserName(name);
  }, []);

  if (location.pathname === '/call' || location.pathname === '/callConnect' || location.pathname === '/terms' || location.pathname === '/permissions') {
    return null;
  }
  return (
    <div className="fixed bottom-0 bg-[#efcd2f] z-[1000] text-black w-full text-[20px] font-bold text-center uppercase py-1 hidden">
      {isStaging && 'Staging'}
      {isLocal && 'Local'}
      {isPreProd && 'Preprod'}
      <span>{` - ${browserName}`}</span>
    </div>
  );
};

export default App;
