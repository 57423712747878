import React, { useEffect, useState } from 'react';

const AndroidPermission = () => {
  const [environment, setEnvironment] = useState({
    isAndroid: false,
    isIOS: false,
    isWebView: false,
  });

  const [permissions, setPermissions] = useState({
    camera: null,
    microphone: null,
    location: null,
  });

  // Detect environment
  useEffect(() => {
    const detectEnvironment = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Detect platforms
      const isAndroid = /Android/i.test(userAgent);
      const isIOS = /iPhone|iPad|iPod/i.test(userAgent);

      // Detect WebView (Instagram's in-app browser)
      const isInstagramWebView = userAgent.includes("Instagram");
      const isIOSWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(userAgent);
      const isAndroidWebView =
        /Android.*Version\/\d+\.\d+.*Chrome\/[.0-9]* Mobile/i.test(userAgent) &&
        !/Version\/\d+\.\d+.*Safari/i.test(userAgent);

      const isWebView = isInstagramWebView || isIOSWebView || isAndroidWebView;

      // Log results in console
      console.log("User Agent: ", userAgent);
      console.log("Platform: ", isAndroid ? "Android" : isIOS ? "iOS" : "Other");
      console.log("Is WebView: ", isWebView);   

      setEnvironment({ isAndroid, isIOS, isWebView });
    };

    detectEnvironment();
  }, []);

  // Request permissions
  useEffect(() => {
    if (environment.isAndroid && !environment.isWebView) {
      // Request camera and microphone permissions
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then(() => {
          alert("Camera and Microphone permission granted");
          setPermissions((prev) => ({ ...prev, camera: "granted", microphone: "granted" }));
        })
        .catch(() => {
          alert("Camera or Microphone permission denied");
          setPermissions((prev) => ({ ...prev, camera: "denied", microphone: "denied" }));
        });
    }

    // Request location permission
    if (environment.isAndroid) {
      navigator.geolocation.getCurrentPosition(
        () => {
          alert("Location permission granted");
          setPermissions((prev) => ({ ...prev, location: "granted" }));
        },
        () => {
          alert("Location permission denied");
          setPermissions((prev) => ({ ...prev, location: "denied" }));
        }
      );
    }
  }, [environment.isAndroid, environment.isWebView]);

  return (
    <div style={{ fontFamily: "Arial, sans-serif", textAlign: "center", padding: "20px" }}>
      <h1>Environment Detection</h1>
      <p>
        <strong>Platform:</strong>{" "}
        {environment.isAndroid
          ? "Android"
          : environment.isIOS
          ? "iOS"
          : "Other"}
      </p>
      <p>
        <strong>Web View:</strong> {environment.isWebView ? "Yes" : "No"}
      </p>
      <p>
        <strong>Permissions:</strong>
      </p>
      <ul>
        <li>Camera: {permissions.camera || "Checking..."}</li>
        <li>Microphone: {permissions.microphone || "Checking..."}</li>
        <li>Location: {permissions.location || "Checking..."}</li>
      </ul>
      {environment.isWebView && (
        <p style={{ color: "red" }}>
          Some features (like camera access) may not work properly in Instagram's in-app browser. Please try opening this link in a standard browser.
        </p>
      )}
      <p>Open the console to see more details.</p>
    </div>
  );
};

export default AndroidPermission;
